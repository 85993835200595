import React from "react"
import './App.css';
// import { Typography } from "@mui/material";
import HomePage from "./HomePage";
import MainHomePage from "./MainHomePage";

function App() {
  return (
    <>
    
      <HomePage />
      
    </>
  );
}

export default App;
