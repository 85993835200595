import React, { useEffect, useState } from "react";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
import { app, analytics, db, storage } from '../firebase';
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function AllPosts() {
    const [posts, setPosts] = useState([]);
    const [fallback, setFallback] = useState(false);

    useEffect(() => {
        const db = getFirestore(app);
        const newPostsDocRef = doc(db, "posts", "newPosts");

        const unsubscribe = onSnapshot(newPostsDocRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
                const data = docSnapshot.data();
                if (data.posts) {
                    setPosts(data.posts.reverse());
                }
            }
        });

        return () => unsubscribe();
    }, []);

    const reloadSrc = (e) => {
        if (fallback) {
            e.target.src = "/img/blank_profile.png"; // Fallback image
        } else {
            e.target.src = e.target.src; // Retry loading the image
            setFallback(true);
        }
    };

    return (
        <>

            <div className="Home" style={{ minHeight: '100vh' }}>
                <h2 style={{ textAlign: 'center', color: 'white', paddingTop: '10px' }}>Posts By Prasad Ghantasala</h2>

                <Container style={{ padding: '50px' }}>
                    <Row>
                        {posts.map((post, index) => (
                            <Col xs={12} sm={6} md={4} lg={3} key={index}>
                                <Card style={{ marginBottom: '1rem' }}>
                                    <Card.Img variant="top" src={post.imageUrl} alt={post.title} />
                                    <Card.Body>
                                        <Card.Title>{post.title}</Card.Title>
                                        <Card.Text dangerouslySetInnerHTML={{ __html: post.content }} />
                                        {/* You can add more content here if needed */}
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default AllPosts;
