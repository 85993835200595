import React, { useEffect, useState } from "react";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
import { app, analytics, db, storage } from '../firebase';
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from 'react-router-dom';
import { Visibility, Add } from "@mui/icons-material";


function Posts() {
    const [posts, setPosts] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const db = getFirestore(app);
        const newPostsDocRef = doc(db, "posts", "newPosts");

        const unsubscribe = onSnapshot(newPostsDocRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
                const data = docSnapshot.data();
                if (data.posts) {
                    setPosts(data.posts.reverse());
                }
            }
        });

        return () => unsubscribe();
    }, []);

    return (
        <Container>
            <div style={{ display: 'flex', gap: 7, alignItems: 'flex-end', justifyContent: 'flex-end', margin: '10px 0', alignContent: 'flex-end', marginBottom: '20px' }}>
                <Button variant="warning" onClick={() => navigate('/all-posts')}>
                    <Visibility /> View All Posts
                </Button>
                <Button variant="warning" onClick={() => navigate('/BlogForm')}>
                    <Add /> Add New Post
                </Button>
            </div>
            <Row>
                {posts.slice(0, 4).map((post, index) => (
                    <Col xs={12} sm={6} md={4} lg={3} key={index}>
                        <Card style={{ marginBottom: '1rem' }}>
                            <Card.Img variant="top" src={post.imageUrl} alt={post.title} />
                            <Card.Body>
                                <Card.Title>{post.title}</Card.Title>
                                <Card.Text dangerouslySetInnerHTML={{ __html: post.content }} />
                                {/* You can add more content here if needed */}
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
}

export default Posts;
