// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"; // Import Firestore
import { getStorage } from "firebase/storage"; // Import Storage

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyB3LRBOLZKFZ43Loq-BDDlDxkx3LsOugKY",
    authDomain: "ghantasalaarts-57d43.firebaseapp.com",
    projectId: "ghantasalaarts-57d43",
    storageBucket: "ghantasalaarts-57d43.appspot.com",
    messagingSenderId: "868830517738",
    appId: "1:868830517738:web:9f3c41c85973d3472a3a93",
    measurementId: "G-JGMKVXMQSL",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Storage
const storage = getStorage(app);

// Export the services you want to use in other parts of your app
export { analytics, db, storage };
