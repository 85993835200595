const Questions = [

    {id:0,question:"నేను తలచిన పని నెరవేరుతుందా నెరవేరదా ?"},
    {id:1,question:"శత్రువును నేను జయించగలనా లేదా ?"},
    {id:2,question:"నేను ప్రభుత్వ ఉద్యోగమును పొందగలనా లేదా ?"},
    {id:3,question:"నాకు వ్యాపారం కలిసి వస్తుందా రాదా ?"},
    {id:4,question:"నాకు సుఖపడే యోగము ఉందా లేదా ?"},
    {id:5,question:"గృహప్రవేశమునకు మంచి ముహూర్తం కావాలి ?"},
    {id:6,question:"సంతానం కలుగుతుందా కలగదా ?"},
    {id:7,question:"వివాహం త్వరగా అవుతుందా ఆలస్యం అవుతుందా ?"},
    {id:8,question:"నాకు ఏ వ్యాపారం కలిసి వస్తుంది ?"},
    {id:9,question:"నాకు మంచి సమయం ఎప్పుడు లభిస్తుంది ?"},
    {id:10,question:"నా వివాహ జీవితం ఎలా ఉంటుంది ?"},
    {id:11,question:"నాకు సినిమా రంగం కలిసి వస్తుందా రాదా ?"},
    {id:12,question:"నాకు కుజదోషం ఉందా లేదా ?"}

]


export default Questions;



