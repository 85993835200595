import React, { useState } from "react";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getFirestore, doc, setDoc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { app } from "../firebase";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Publish } from "@mui/icons-material"; // Import the Publish icon
import { Input, InputLabel } from "@mui/material";

function BlogForm() {
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [loading, setLoading] = useState(false);
    const [imageLoading, setImageLoading] = useState(false); // New state for image loading
    const navigate = useNavigate();

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        const storage = getStorage(app);
        const storageRef = ref(storage, `images/${file.name}`);
        setImageLoading(true); // Set image loading to true
        await uploadBytes(storageRef, file);
        const url = await getDownloadURL(storageRef);
        setImageUrl(url);
        setImageLoading(false); // Set image loading to false after upload
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!imageUrl) {
            alert("Please upload an image before submitting.");
            return;
        }
        setLoading(true);
        const db = getFirestore(app);
        const newPost = { title, content, imageUrl };
        const newPostsDocRef = doc(db, "posts", "newPosts");

        const docSnap = await getDoc(newPostsDocRef);
        if (!docSnap.exists()) {
            await setDoc(newPostsDocRef, { posts: [] });
        }

        await updateDoc(newPostsDocRef, {
            posts: arrayUnion(newPost),
        });

        setLoading(false);
        // Reset form and navigate to homepage
        setTitle("");
        setContent("");
        setImageUrl("");
        navigate("/");
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', background: '#378CE7' }}>
            <form onSubmit={handleSubmit} style={{ padding: '0 20px' }}>

                <Input
                    variant="outlined"
                    id="outlined-title"
                    label="Post Title"
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Post Title"
                    style={{ marginBottom: '15px', color: 'white' }}
                />
                <ReactQuill style={{ marginBottom: '15px', color: 'white' }}
                    value={content} onChange={(content) => setContent(content)} />
                <input type="file" onChange={handleImageUpload} />
                <div style={{ marginTop: '15px' }}>
                    <Button style={{ background: 'black' }} type="submit" disabled={loading || imageLoading}>
                        {loading || imageLoading ? "Uploading..." : <><Publish />  Publish Post</>}
                    </Button>
                </div>

                {loading && <div>Loading...</div>}
            </form>
        </div>
    );
}

export default BlogForm;
