import React from "react";
import Shiva from "./Shiva";
import Navrbar from "./Navbar";


export default function ShivaParvathi(){

    return(
        <div style={{margin:'0px',minWidth:'100%'}}>
            
        <Navrbar/>
     
        
            <Shiva/>
        </div>
    )
}